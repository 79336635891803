import * as configcat from "configcat-js-ssr";
import { getEnvironment } from "@utils/common";
import type { IConfigCatClient } from "configcat-common";
import type { OpCo } from "@utils/use-brand";

const configCatClient = new Map<OpCo, IConfigCatClient>();

function initConfigCatClient(opCo: OpCo) {
  let configCatKey: string | undefined = undefined;
  const environment = getEnvironment();

  if (environment === "local" && !process.env.NEXT_PUBLIC_OVERRIDE_OPCO) {
    configCatKey = process.env.NEXT_PUBLIC_CONFIGCAT_KEY_DEV;
  } else if (environment === "staging") {
    configCatKey = process.env.NEXT_PUBLIC_CONFIGCAT_KEY_STAGING;
  } else {
    switch (opCo) {
      case "liber":
        configCatKey = process.env.NEXT_PUBLIC_CONFIGCAT_KEY_LIBER;
        break;
      case "plantyn":
        configCatKey = process.env.NEXT_PUBLIC_CONFIGCAT_KEY_PLANTYN;
        break;
      // "noordhoff":
      default:
        configCatKey = process.env.NEXT_PUBLIC_CONFIGCAT_KEY_JUNIORCLOUD;
        break;
    }
  }

  if (!configCatKey) {
    throw `Missing ConfigCatKey for ${opCo}`;
  }

  const client = configcat.getClient(configCatKey, configcat.PollingMode.LazyLoad, {
    cacheTimeToLiveSeconds: 600,
  });

  configCatClient.set(opCo, client);

  return client || null;
}

export function getConfigCatClient(opCo: OpCo): IConfigCatClient | null {
  const opCoClient = configCatClient.get(opCo);

  if (!opCoClient) {
    return initConfigCatClient(opCo);
  }

  return opCoClient;
}
